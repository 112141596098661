import moment from 'moment';
import { gql, useQuery } from '@apollo/client';

export const LIST_CLIENT_DOCUMENT_RELATIONSHIPS = gql`
  query clientDocumentRelationships($clientId: ID!, $clientDocumentIds: [ID]) {
    clientDocumentRelationships: listClientDocumentRelationships(
      clientId: $clientId
      clientDocumentIds: $clientDocumentIds
    ) {
      id
      insuredId
      clientDocumentId
      exposureElections {
        id
        startDate
        terminationDate
      }
      insured {
        id
      }
    }
  }
`;

export default function useListClientDocumentRelationships({
  clientId,
  clientDocumentIds,
  onCompleted = () => {},
  onError = () => {}
}) {
  const { data, loading, error } = useQuery(LIST_CLIENT_DOCUMENT_RELATIONSHIPS, {
    variables: { clientId, clientDocumentIds },
    skip: !clientId,
    onCompleted,
    onError
  });

  const clientDocumentRelationships = data?.clientDocumentRelationships;
  const todayFormatted = moment().format(moment.HTML5_FMT.DATE);

  const filteredDocumentRelationships = clientDocumentRelationships?.filter(documentRelationship =>
    documentRelationship?.exposureElections?.filter(exposureElection => exposureElection?.startDate > todayFormatted)
      ?.length === 0
      ? true
      : false
  );

  const allInsuredIds = filteredDocumentRelationships?.map(({ insuredId }) => insuredId);

  const allValidInsuredIds = filteredDocumentRelationships?.map(({ insuredId }) => insuredId);

  const clientDocumentInsuredsMapping = filteredDocumentRelationships?.reduce(
    (acc, { clientDocumentId, insuredId }) => {
      return acc[clientDocumentId]
        ? { ...acc, [clientDocumentId]: [insuredId, ...acc[clientDocumentId]] }
        : { [clientDocumentId]: [insuredId], ...acc };
    },
    {}
  );

  return {
    allInsuredIds: unique(allInsuredIds) || [],
    allValidInsuredIds: unique(allValidInsuredIds) || [],
    allDocumentInsuredsMapping: clientDocumentInsuredsMapping || {},
    allValidDocumentRelationships: filteredDocumentRelationships || [],
    loading,
    error
  };
}

const unique = ids => [...new Set(ids)];
